import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ReactNode, useMemo } from "react";
import { SkewMainContainer } from "../../components/Container";
import { SectionDescribeText } from "../../components/Section";
import { SectionTitle } from "../../components/Title";
import { Avatar01, Avatar02, Avatar03, Avatar04 } from "../../images";
import { useLanguage } from "../../utils/utils";

const CoreTeamList = () => {
  const { t } = useTranslation();
  const coreTeamList: CoreTeamListItem[] = useMemo(
    () => [
      {
        name: t("章磊"),
        position: t("首席执行官"),
        desc: t(
          "作为连续创业者，章磊先生曾在世界银行、华尔街、硅谷从事算法工作10多年。涉及金融、保险、投资量化、风险模型、人工智能、区块链算法等多个方向。毕业于浙江大学以及乔治华盛顿大学。"
        ),
        avatar: <img src={Avatar01} alt="" className="rounded-t-lg" />,
      },
      {
        name: t("徐铭锴"),
        position: t("首席运营官"),
        desc: t(
          "连续创业者。前京东AI+商务战略负责人，深度参与高层战略与核心项目。曾在硅谷独角兽科技公司成功搭建多个垂直领域的商业智能分析架构与数据解决方案（数据科学，推荐系统工程，产品，金融财务，市场，商务销售以及人力资源分析等），为管理团队提供数据支持与决策数据分析。毕业于哥伦比亚大学。"
        ),
        avatar: <img src={Avatar02} alt="" className="rounded-t-lg" />,
      },
      {
        name: t("熊梓陶"),
        position: t("首席技术官"),
        desc: t(
          "有10余年前后端开发经验，在iheartradio，makespace，sevenroom等多家公司任职资深架构师，经手项目几十个。精通各类技术框架。擅长技术架构设计和解决系统瓶颈问题。曾用两个月时间改版项目，月流量突破1亿。普渡大学毕业。"
        ),
        avatar: <img src={Avatar03} alt="" className="rounded-t-lg" />,
      },
      {
        name: t("董磊"),
        position: t("顾问"),
        desc: t(
          "清华大学双学士、博士，哈佛大学访问学者，MIT博士后。曾工作于百度研究院大数据实验室，从事数据分析与机器学习算法工作。"
        ),
        avatar: <img src={Avatar04} alt="" className="rounded-t-lg" />,
      },
    ],
    [t]
  );
  return (
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-8 mt-8">
      {coreTeamList.map((list) => (
        <CoreTeamItem key={list.name} {...list} />
      ))}
    </div>
  );
};

type CoreTeamListItem = {
  name: string;
  position: string;
  desc: string;
  avatar: ReactNode;
};

const CoreTeamItem = (props: CoreTeamListItem) => {
  const { isEnUs } = useLanguage();
  return (
    <div>
      <div className="news-card rounded-lg bg-white">
        {props.avatar}
        <div className={isEnUs ? "p-4 h-[450px]" : "p-4 h-80"}>
          <h4 className="text-base font-bold mt-4">{props.name}</h4>
          <p className="text-xs text-gray-400 mt-2 mb-4">{props.position}</p>
          <p
            className={
              isEnUs
                ? "text-xs leading-5 text-gray-500 leading-snug"
                : "text-xs leading-5 text-gray-500"
            }
          >
            {props.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

const CoreTeamView = () => {
  const { t } = useTranslation();

  return (
    <SkewMainContainer fillClassName="bg-gray-50">
      <SectionTitle id="coreTeam" className="mb-6">
        {t("核心团队")}
      </SectionTitle>
      <SectionDescribeText className="leading-7">
        {t(
          "我们是一支世界级的团队，正在改变AI行业的运作方式。我们曾在硅谷、华尔街等地工作过，有着从创业公司、硅谷独角兽公司到上市公司从0到1的经验。星尘聚集了国内外人工智能科学家，数据处理数过亿，立志打造世界顶尖AI数据中台系统。"
        )}
      </SectionDescribeText>
      <CoreTeamList />
    </SkewMainContainer>
  );
};
export default CoreTeamView;

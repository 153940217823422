import * as React from "react";
import {
  cover_car,
  cover_dots_01,
  cover_dots_02,
  cover_dots_03,
  cover_face,
  cover_light_ring1,
  cover_light_ring2,
  cover_light_ring3,
  cover_people1,
  cover_people2,
  cover_stage_big,
  cover_wave,
  cove_light,
} from "../images/about";

const AboutCover = () => {
  return (
    <div className="w-[896px] h-[841px] relative">
      <img className="absolute w-full inset-0" src={cover_stage_big} alt="" />
      <img
        className="animation_light absolute"
        src={cove_light}
        width="99"
        height="207"
        style={{ left: 400, top: 38 }}
        alt="装饰性图片"
      />
      <img
        className="animation_dots01 absolute"
        src={cover_dots_01}
        width="153"
        height="112"
        style={{ left: 372, top: 154 }}
        alt="装饰性图片"
      />
      <img
        className="animation_dots02 absolute"
        src={cover_dots_02}
        width="148"
        height="76"
        style={{ left: 372, top: 154 }}
        alt="装饰性图片"
      />
      <img
        className="animation_dots03 absolute"
        src={cover_dots_03}
        width="126"
        height="65"
        style={{ left: 372, top: 154 }}
        alt="装饰性图片"
      />
      <img
        className="animation_car absolute"
        src={cover_car}
        width="98"
        height="88"
        style={{ left: 424, top: 12 }}
        alt="装饰性图片"
      />
      <img
        className="animation_light-ring-1"
        src={cover_light_ring1}
        width="52"
        style={{ position: "absolute", left: 66, top: 328 }}
        alt="装饰性图片"
      />
      <img
        className="animation_light-ring-2"
        src={cover_light_ring2}
        width="44"
        style={{ position: "absolute", left: 708, top: 128 }}
        alt="装饰性图片"
      />
      <img
        className="animation_wave"
        src={cover_wave}
        width="65"
        style={{ position: "absolute", right: 74, top: 266 }}
        alt="装饰性图片"
      />
      <img
        className="animation_face"
        src={cover_face}
        width="32"
        style={{ position: "absolute", left: 455, top: 326 }}
        alt="装饰性图片"
      />
      <img
        className="animation_light-ring-3"
        src={cover_light_ring3}
        width="96"
        style={{ position: "absolute", left: 372, top: 338 }}
        alt="装饰性图片"
      />
      <img
        src={cover_people1}
        width="55"
        style={{ position: "absolute", left: 332, top: 360 }}
        alt="装饰性图片"
      />
      <img
        src={cover_people2}
        width="32"
        style={{ position: "absolute", left: 460, top: 379 }}
        alt="装饰性图片"
      />
    </div>
  );
};

export default AboutCover;

import { Button, Modal } from "antd";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { SkewMainContainer } from "../../components/Container";
import { Input } from "../../components/SearchInput";
import { ContactRightArrow } from "../../images";
import { AboutJob, AboutNews, submit_suc } from "../../images/about";

const API_KEY =
  "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";

const ContactView = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const submitHandler = useCallback(async () => {
    setLoading(true);
    await fetch("https://api.sendinblue.com/v3/contacts", {
      body: JSON.stringify({ updateEnabled: false, email: value }),
      headers: {
        "api-key": API_KEY,
        "content-type": "application/json",
      },
      method: "POST",
    });
    setLoading(false);
    setValue("");
    setModalVisible(true);
  }, [value]);
  const lists = useMemo(
    () => [
      {
        title: t("公司新闻"),
        desc: t("了解更多关于公司的新闻和技术分享。"),
        color: "#6972DE",
        icon: <AboutNews />,
        linkUrl: "/news",
      },
      {
        title: t("人才招聘"),
        desc: t("了解更多关于公司的新闻和技术分享。"),
        color: "#57B182",
        icon: <AboutJob />,
        linkUrl: "/recruitment",
      },
    ],
    [t]
  );
  return (
    <SkewMainContainer fillClassName="bg-gray-50">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {lists.map((list) => (
          <div
            className="about-card news-card"
            onClick={() => navigate(list.linkUrl)}
          >
            {list.icon}
            <div className="ml-4">
              <h3 className="flex text-xl">
                <p className="mr-2" style={{ color: list.color }}>
                  {list.title}
                </p>
                <ContactRightArrow fill={list.color} />
              </h3>
              <p className="disclaimer-color mt-3">{list.desc}</p>
            </div>
          </div>
        ))}
      </div>
      <h3 className="text-xl mt-20">{t("订阅星尘邮件")}</h3>
      <p className="disclaimer-color mt-4">
        {t("告诉我们您的email地址，将为您发送产品手册和公司新闻。")}
      </p>
      <div className="w-full sm:w-1/2 flex px-4 items-center bg-white custom-shadow-20 mt-8 rounded">
        <Input
          className="h-12"
          value={value}
          placeholder="youremail@example.com"
          onChange={(value) => setValue(value)}
        />
        <Button
          type="text"
          loading={loading}
          style={{ color: "color: rgba(73, 59, 255, 1)" }}
          className="flex-none"
          onClick={submitHandler}
        >
          {t("提交订阅")}
        </Button>
      </div>
      <Modal
        width={240}
        visible={modalVisible}
        title={null}
        footer={null}
        centered
        closable={false}
        onCancel={() => setModalVisible(false)}
      >
        <div className="flex flex-col items-center">
          <img className="w-12 h-12 mb-4" src={submit_suc} alt="" />
          <p className="text-md">{t("提交成功")}</p>
        </div>
      </Modal>
    </SkewMainContainer>
  );
};
export default ContactView;

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import AboutCover from "../components/AboutCover";
import { MainContainer, PageContainer } from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import SkewGradientBg from "../components/SkewGradientBg";
import { mobile_stardust_cover } from "../images/about";
import { usePageMetadata } from "../metadata/page";
import { useLanguage, useMediaScreen } from "../utils/utils";
import CompanyIntroductionView from "../Views/ AboutViews/CompanyIntroductionView";
import ContactView from "../Views/ AboutViews/ContactView";
import CoreTeamView from "../Views/ AboutViews/CoreTeamView";
import ProductArchitectureView from "../Views/ AboutViews/ProductArchitectureView";
import LearnMoreView from "../Views/LearnMoreView";

const AboutPage = () => {
  const { t } = useTranslation();
  const { about } = usePageMetadata();
  const { isSmScreen } = useMediaScreen();
  const { isEnUs } = useLanguage();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.light} />
      <Metadata {...about} />
      <SkewGradientBg
        id="gradient-about"
        className="h-[944px]"
        gradientHeight={944}
      />
      <MainContainer style={{ zIndex: -1 }}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div>
            <h3 className="text-white mt-14 mb-10">
              <p className="mb-4">{t("星尘使命")}</p>
              <p className="text-3xl"> {t("重新定义机器智慧的高度")}</p>
            </h3>
            <div className="sm:w-[500px]">
              <p
                className={
                  isEnUs
                    ? "mb-8 text-white text-md leading-tight"
                    : "mb-8 text-white text-md"
                }
              >
                {t(
                  "星尘数据（公司全称：北京星尘纪元智能科技有限公司），是一家提供数据标注服务和AI数据中台系统的SaaS公司。我们为全球人工智能企业提供服务，为计算机视觉、语音识别、NLP任务提供了高质量的训练数据。我们专注于提供行业领先的AI数据中台系统，实现模型的生命周期中的数据生产、数据管理、模型训练、模型部署等环节。"
                )}
              </p>
              <p
                className={
                  isEnUs
                    ? "mb-8 text-white text-md leading-tight"
                    : "mb-8 text-white text-md"
                }
              >
                {t(
                  "数据作为AI算法的电能，如何在保证质量的前提下降低成本并大规模生产是行业的痛点。我们的数据处理平台Stardust提供AI数据全流程标注和质量管理，它提供了数据质量、成本、可扩展性的最佳解决方式。"
                )}
              </p>
            </div>
          </div>
          <div className="relative">
            {isSmScreen && (
              <div
                className="sm:absolute sm:-left-32 sm:-top-28 -mt-8 ml-8"
                style={{ transform: "scale(.60)", zIndex: -1 }}
              >
                <AboutCover />
              </div>
            )}
            {!isSmScreen && <img src={mobile_stardust_cover} alt="" />}
          </div>
        </div>
      </MainContainer>
      <CompanyIntroductionView />
      <CoreTeamView />
      <ProductArchitectureView />
      <ContactView />
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage($language: String!) {
    ...AllLangauages
  }
`;

import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useMemo } from "react";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import {
  product_architecture,
  product_architecture_en,
} from "../../images/about";
import { useLanguage } from "../../utils/utils";

const ProductArchitectureView = () => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  const lists = useMemo(
    () => [
      {
        title: t("应用场景"),
        desc: t(
          "覆盖无人车、智能客服、机器人、智慧城市、电商零售等12大类，数百小类应用场景，并支持私有化特殊场景的定制，覆盖90%以上标注场景。"
        ),
      },
      {
        title: t("算法研发"),
        desc: t(
          "包含2D/3D 物体检测、2D/3 D语义分割、2D/3D MOT、2D/3D SOT、OCR、ASR、文本实例、关键词等数十种算法，支持自定义算法接入，利用算法为标注服务赋能。"
        ),
      },
      {
        title: t("基础设施"),
        desc: t(
          "包含容器化部署、弹性伸缩、函数计算、API 服务、云监控、冷热备份、数据生产线、加密传输、全球加速等基础服务能力，有效、快速、弹性、安全地支撑上层业务。"
        ),
      },
    ],
    [t]
  );
  return (
    <SkewMainContainer fillClassName="bg-white">
      <SectionTitle id="productArchitecture" className="mb-6">
        {t("产品架构")}
      </SectionTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 ">
        <img
          src={isEnUs ? product_architecture_en : product_architecture}
          alt=""
        />
        <div className="mt-4">
          {lists.map(({ title, desc }) => (
            <div className="mb-8">
              <h3 className="text-lg mb-4">{title}</h3>
              <p className="disclaimer-color">{desc}</p>
            </div>
          ))}
        </div>
      </div>
    </SkewMainContainer>
  );
};

export default ProductArchitectureView;

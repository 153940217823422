import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Tilt from "react-tilt";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import { Proverb } from "../../images";
import { about_chart, about_chart_en } from "../../images/about";
import { useLanguage } from "../../utils/utils";

const CompanyIntroductionView = () => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  const introduceTexts = [
    t(
      "星尘数据是一家提供数据标注服务和AI数据中台系统的SaaS公司。我们为全球人工智能企业提供服务，为计算机视觉、语音识别、NLP任务提供了高质量的训练数据。我们专注于提供行业领先的AI数据中台系统，实现模型的生命周期中的数据生产、数据管理、模型训练、模型部署等环节。"
    ),
    t(
      "数据作为AI算法的电能，如何在保证质量的前提下降低成本并大规模生产是行业的痛点。我们的数据处理平台Stardust提供AI数据全流程标注和质量管理，它提供了数据质量、成本、可扩展性的最佳解决方式。"
    ),
  ];
  const addTexts = [
    t(
      "数据量、算力、理论体系，经过50年积累，互联网和芯片技术终于让AI变成了实在的效率提升工具"
    ),
    t("十四五规划里的重头内容，新基建、数字中国、大国之争"),
    t(
      "从互联网时代到人工智能时代，标数据和写代码一样，都是变革中最底层的推动力"
    ),
  ];

  const countText = [
    { text: t("条数据已处理"), count: "1,000,000,000+" },
    { text: t("个项目交付"), count: "500+" },
    { text: t("场景支持"), count: "100+" },
  ];

  return (
    <SkewMainContainer fillClassName="bg-white">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-16">
        <div>
          <SectionTitle id="companyIntroduction" className="text-xl mb-4">
            {t("AI数据需求的增长")}
          </SectionTitle>
          {addTexts.map((text) => (
            <div className="flex items-start mb-2">
              <div className="bg-[#455364] w-1 h-1 rounded-full mr-2 mt-2" />
              <p className="disclaimer-color leading-5">{text}</p>
            </div>
          ))}
          <h4 className="text-lg mt-12 mb-6">{t("星尘处理量")}</h4>
          <div className="flex justify-between">
            {countText.map(({ text, count }) => (
              <div className="flex flex-col items-start">
                <p className="primary-text text-2xl mb-2">{count}</p>
                <p className="disclaimer-color">{text}</p>
              </div>
            ))}
          </div>
        </div>
        <Tilt
          className="Tilt w-[400px] h-72 bg-purple-50 py-12 rounded-md flex justify-center items-center filter hover:drop-shadow-xl"
          options={{
            max: 15,
            reverse: true,
            speed: 1500,
            perspective: 2000,
          }}
        >
          <div className="Tilt-inner">
            <img
              className="w-[298px]"
              src={isEnUs ? about_chart_en : about_chart}
              alt=""
            />
          </div>
        </Tilt>
        <Tilt
          className="Tilt w-[400px] h-72 bg-purple-100 py-12 rounded-md flex justify-center items-center filter hover:drop-shadow-xl"
          options={{
            max: 15,
            reverse: true,
            speed: 1500,
            perspective: 2000,
          }}
        >
          <div className="Tilt-inner">
            <img className="w-[298px]" src={Proverb} alt="" />
          </div>
        </Tilt>

        <div>
          <h3 className="text-xl mb-8">{t("公司介绍")}</h3>
          {introduceTexts.map((text) => (
            <p className="mb-12 text-xs disclaimer-color leading-6">{text}</p>
          ))}
        </div>
      </div>
    </SkewMainContainer>
  );
};
export default CompanyIntroductionView;
